.tables {
    width: 100%;
    height: 85vh;
    background-color: #fff;
    overflow-x: scroll;
    :global {
        .ant-popover-inner {
            .ant-popover-title {
                font-size: 14px;
                font-family: Inter-Regular;
            }
            .ant-popover-inner-content {
                .link-pid {
                    line-height: 20px;
                    color: #333;
                    font-family: Inter-Regular;
                    margin-top: 20px;
                }
            }
        }
        .ant-table-pagination {
            position: sticky;
            bottom: 0;
            background: white;
            padding: 10px 0;
            border-top: 1px solid #f0f0f0;
        }

        .ant-table-container {
            .ant-table-content {
                .ant-table-tbody {
                    .ant-table-row {
                        .ant-table-cell {
                            .tier-icon {
                                display: inline-block;
                                width: 12px;
                                height: 12px;
                                background: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/icons/1689750970284_changeTier.svg')
                                    center no-repeat;
                                background-size: contain;
                                cursor: pointer;
                            }
                            .risk {
                                display: inline-block;
                                width: 64px;
                                height: 22px;
                                border-radius: 2px;
                                font-size: 14px;
                                text-align: center;
                                color: #fff;
                                margin-right: 12px;
                                &.B {
                                    background: #f55e0f;
                                }
                                &.A {
                                    background: #d92929;
                                }
                                &.C {
                                    background: #24ca21;
                                }
                            }
                            .tag {
                                .hidden {
                                    display: none;
                                }
                                margin-top: 8px;
                                white-space: nowrap;
                                background: rgba(0, 0, 0, 0.02);
                                border: 1px solid #d9d9d9;
                                border-radius: 4px;
                                padding-inline: 7px;
                                display: inline-block;
                            }
                            .Automatic {
                                background: blue;
                                width: 86px;
                                height: 23px;
                                border-radius: 2px;
                            }
                            .Rejected {
                                background: red;
                                width: 86px;
                                height: 23px;
                                border-radius: 2px;
                            }
                            .reject {
                                margin-right: 5px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 2px;
                                font-size: 13px;
                                color: #fff;
                                font-family: Inter-medium;
                            }
                            .Done {
                                background: #61c743;
                                width: 65px;
                                height: 23px;
                            }
                            .In {
                                width: 86px;
                                height: 23px;
                                background: #fb9927;
                            }
                            .Not {
                                background: #c1c1c1;
                                height: 23px;
                                width: 86px;
                            }
                            .option-btn {
                                .reject {
                                    border-radius: 4px;
                                    background: #f3f3f3;
                                    color: #b0b0b0;
                                    font-family: Inter-semibold;
                                    display: flex;
                                    line-height: normal;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 8px 12px;
                                }
                                display: flex;
                                // > &:span {
                                //   margin-bottom: 9px;
                                // }
                                span {
                                    width: 100%;
                                    cursor: pointer;
                                    display: inline-block;
                                    height: 24px;
                                    font-size: 12px;
                                    border-radius: 4px;
                                    text-align: center;
                                    line-height: 23px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
