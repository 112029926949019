.user-login {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    :global {
        .logo {
            position: absolute;
            top: 46px;
            left: 41px;
            width: 180px;
            height: 29px;
            background: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/icons/1688375119891_yumi_KYC2.svg')
                center no-repeat;
        }
    }
}
