.Layout {
    width: 250px;
    height: 100%;
    display: flex;
    :global {
        .user {
            height: 100%;
            width: 250px;
            background: linear-gradient(180deg, #2c50ee 0%, #2548e6 100%);
            color: #fff;
            // padding-left: 32px;
            .user-info {
                margin: 50px 0 51px 32px;
                display: flex;
                align-items: center;
                img {
                    width: 46px;
                    height: 46px;
                    object-fit: contain;
                    margin-right: 15px;
                }
                span {
                    font-size: 16px;
                }
            }
            .cells {
                .ant-menu-submenu > .ant-menu {
                    // background-color: rgb(16, 71, 83) !important;
                }
                .ant-menu-title-content {
                    color: #fff !important;
                    font-size: 16px;
                }
                .ant-menu-submenu-arrow {
                    color: #fff !important;
                }
                .ant-menu-item a:hover {
                    color: #fff;
                }
                .ant-menu-item-active {
                    color: #fff !important;
                }
                .ant-menu-item-selected {
                    background-color: unset;
                    // color: white !important;
                }
            }
        }
    }
}
