.link-pid-modal {
    :global {
        .ant-modal-content {
            .ant-modal-header {
                .ant-modal-title {
                    font-size: 30px;
                }
            }
            .ant-modal-body {
                text-align: center;
                input {
                    width: 100%;
                    height: 64px;
                    max-height: 66px;
                    outline-style: none;
                    background: #d9d9d9;
                    border: none;
                    margin-top: 15px;
                }
            }
        }
    }
}
