.reject-modal {
    :global {
        .other {
            display: flex;
            align-items: center;
            margin: 27px 0;
            div {
                color: #ff4d4f;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline-end: 4px;
                font-family: SimSun, sans-serif;
            }
        }
        input {
            width: 100%;
            max-width: 272px;
            height: 32px;
            outline-style: none;
            border-radius: 4px;
            border: 1px solid #e4e4e4;
        }
        .btn-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btn {
                &.cancel {
                    background: #fff;
                    color: #333333;
                    border: 1px solid #aeaeae;
                }
                display: flex;
                align-items: center;
                justify-content: center;
                // background-color: #3a77f6;
                font-size: 14px;
                min-width: 79px;
                height: 32px;
                border-radius: 6px;
                // color: #fff;
                cursor: pointer;
            }
        }
    }
}
