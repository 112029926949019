.applicantModal {
    display: none;
    position: absolute;
    background: rgba(31, 28, 28, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    &.open {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    :global {
        .close {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            cursor: pointer;
        }
        .modal {
            padding: 0 12px;
            width: 845px;
            border-radius: 16px;
            background: #f7f9fa;
            min-height: 515px;
            overflow-y: scroll;
            max-height: 100%;
            .applicant {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .left,
                .right {
                    .label {
                        color: #999;
                        font-size: 14px;
                    }
                    .value {
                        color: #333;
                        font-size: 16px;
                    }
                }
            }
            .img-cover {
                .img-list {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    .cell-img {
                        cursor: pointer;
                        width: 60px;
                        height: 60px;
                        border-radius: 8px;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .cover {
                    .cover-top {
                        max-height: 338px;
                        border-radius: 16px;
                        border: 1px solid #eaecf0;
                        background: #f7f9fa;
                        display: flex;
                        justify-content: center;
                        overflow: hidden;
                        img {
                            max-height: 338px;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        .bottom-image {
            border-radius: 16px;
            border: 1px solid #eaecf0;
            background: #fff;
            margin-bottom: 17px;
            padding: 32px 30px 0;
            .show {
                .label {
                    font-size: 22px;
                    margin-bottom: 19px;
                }
                .text {
                    max-width: 100%;
                    word-wrap: break-word;
                }
                .show-img {
                    margin-bottom: 19px;
                    width: 348px;
                    height: 236px;
                    background: #f1f1f1;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .upload-list {
                    display: flex;
                    align-items: center;
                    .item-list {
                        width: 348px;
                        height: 236px;
                        background: #f1f1f1;
                        margin-right: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .btn {
                margin-bottom: 42px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-top: 104px;
                cursor: pointer;
                div {
                    padding: 5px 25px;
                    border-radius: 2px;
                    background: #8686a5;
                    font-size: 14px;
                    color: #fff;
                }
                a {
                    padding: 5px 25px;
                    border-radius: 2px;
                    background: #8686a5;
                    font-size: 14px;
                    color: #fff;
                    outline: none !important;
                    text-decoration: none;
                }
            }
        }
    }
}
