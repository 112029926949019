.add-account {
    width: calc(100% - 106px);
    // flex: 1;
    height: 90vh;
    margin: 25px 65px 0 41px;
    background-color: #fff;
    border-radius: 14px;
    background: #fff;
    padding: 26px 53px 0 43px;
    :global {
        .cells {
            .cell {
                display: flex;
                height: 51px;
                align-items: center;
                position: relative;
                div {
                    line-height: 49px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    bottom: -1px;
                    background-image: linear-gradient(
                        to right,
                        rgba(#000, 0.25) 50%,
                        transparent 50%
                    );
                    background-size: 15px 1px;
                    background-repeat: repeat-x;
                }
                .label {
                    color: #8686a5;
                    font-size: 14px;
                    height: 100%;
                    min-width: 79px;
                }
                .value {
                    border-bottom: 1px solid #000;
                    font-size: 15px;
                    height: 100%;
                    input {
                        height: 91%;
                        border: none;
                        outline-style: none;
                    }
                    /* 设置 select 的宽度 */
                    select {
                        height: 100%;
                        border: none;
                        outline-style: none;
                    }

                    /* 设置 select 的字体大小 */
                    select {
                        font-size: 16px;
                    }
                    /* 隐藏 select 的下拉箭头 */
                    select::-ms-expand {
                        display: none;
                    }
                    select::-webkit-expand {
                        display: none;
                    }
                    select::-moz-focus-inner {
                        border: 0;
                    }

                    /* 设置 select 中每个选项的样式 */
                    select option {
                        font-size: 14px;
                        background-color: #fff;
                        color: #333;
                    }

                    /* 设置 select 中被选中的选项的样式 */
                    select option:checked {
                        background-color: #007bff;
                        color: #fff;
                    }
                }
            }
        }
        .btn {
            cursor: pointer;
            width: 80px;
            height: 22px;
            border-radius: 2px;
            background: #8686a5;
            color: #fff;
            text-align: center;
            margin: 67px 0 0 80px;
        }
    }
}
