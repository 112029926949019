.change-modal {
    :global {
        .limit {
            display: flex;
            align-items: center;
            margin-top: 30px;
            .ant-input-number {
                margin-left: 20px;
                width: 200px;
            }
        }
    }
}
