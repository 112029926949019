.config-user {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    :global {
        .search {
            margin: 30px 0 40px 50px;
            border-radius: 8px;
            width: 300px;
            height: 40px;
            background-color: #fff;
            display: flex;
            align-items: center;
            overflow: hidden;
            input {
                border: none;
                outline-style: none;
                flex: 1;
                height: 100%;
            }
            ::placeholder {
                color: #cecece;
                text-align: center;
            }
            span {
                display: inline-block;
                width: 24px;
                height: 27px;
                background: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/components/1688540607058_kyc-tool-search.svg')
                    no-repeat center;
                margin-right: 14px;
            }
        }
        .config-list {
            width: calc(100% - 106px);
            height: 85vh;
            margin-left: 50px;
            background-color: #fff;
            border-radius: 14px;
            overflow-y: scroll;
            // padding: 0 44px;
            table {
                margin: 0px 0 0 20px;
                width: calc(100% - 50px);
                thead {
                    tr {
                        position: relative;
                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            left: 0;
                            bottom: 0;
                            background-image: linear-gradient(
                                to right,
                                rgba(#000, 0.25) 50%,
                                transparent 50%
                            );
                            background-size: 15px 1px;
                            background-repeat: repeat-x;
                        }
                    }
                    th {
                        padding: 29px 0 39px;
                        color: #8686a5;
                        .add {
                            cursor: pointer;
                            float: right;
                            text-align: right;
                            width: 108px;
                            height: 33px;
                            border-radius: 6px;
                            background: #000;
                            font-size: 15px;
                            color: #fff;
                            line-height: 32px;
                            text-align: center;
                        }
                    }
                }
                tbody {
                    tr {
                        height: 100px;
                        position: relative;
                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            left: 0;
                            bottom: 0;
                            background-image: linear-gradient(
                                to right,
                                rgba(#000, 0.25) 50%,
                                transparent 50%
                            );
                            background-size: 15px 1px;
                            background-repeat: repeat-x;
                        }
                        td {
                            font-size: 14px;
                        }
                    }

                    .option {
                        display: flex;
                        height: 100%;
                        div {
                            cursor: pointer;
                            padding: 0 10px;
                            height: 22px;
                            border-radius: 2px;
                            background: #8686a5;
                            color: #fff;
                            font-size: 14px;
                            line-height: 21px;
                            text-align: center;
                            margin-right: 12px;
                        }
                    }
                    .role {
                        div {
                            height: 22px;
                            padding: 0 8px;
                            display: inline-block;
                            font-size: 14px;
                            border-radius: 2px;
                            line-height: 21px;
                            text-align: center;
                            color: #fff;
                            background: #24ca21;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
