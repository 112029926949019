.change-log {
    // flex: 1;
    flex: auto;
    min-width: 0;
    padding: 30px 30px 0 30px;
    :global {
        .log-table {
            width: 100%;
            height: 85vh;
            background-color: #fff;
        }
    }
}
