.filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    :global {
        .select {
            .ant-select {
                margin-right: 44px;
                width: 136px;
                height: 40px;
                .ant-select-selector {
                    height: 100%;
                    border: none;
                    display: flex;
                    padding: 0 33px;
                    align-items: center;
                    .ant-select-selection-search {
                        line-height: 39px;
                        input {
                        }
                    }
                }
                .ant-select-arrow {
                    right: 33px;
                }
            }
        }
        .btn {
            text-decoration: none;
            cursor: pointer;
            width: 91px;
            height: 40px;
            max-height: 40px;
            border-radius: 6px;
            background: #6f7e90;
            color: #fff;
            font-size: 15px;
            line-height: 39px;
            text-align: center;
        }
    }
}
